import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import baseUrl from "../../utils/config";
import Cookies from "js-cookie"

const Sidebar = () => {

  const accessToken = Cookies.get("adminToken");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const admin = await axios.get(`${baseUrl}/check-auth`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (admin.data.data.role == 'superadmin') setIsSuperAdmin(true);

      } catch (error) {
        navigate("/admin-login");
      }
    };
    checkAuth();
  }, []);


  const navigate = useNavigate();
  const { dispatch } = useContext(DarkModeContext);
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">AdminPanel</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <li onClick={() => navigate("/")}>
            <DashboardIcon className="icon" />
            <span>Dashboard</span>
          </li>
          <p className="title">USERS</p>
          <Link to="/user" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>All Users</span>
            </li>
          </Link>
          {
            isSuperAdmin && (
              <>
              <li onClick={() => navigate('/users/new')}>
                <CreditCardIcon className="icon" />
                <span>Add User</span>
              </li>
              <p className="title">ADMINS</p>
              <li onClick={() => navigate('/admins')}>
                <StoreIcon className="icon" />
                <span>All Admins</span>
              </li>
              <li onClick={() => navigate('/admins/new')}>
                <CreditCardIcon className="icon" />
                <span>Add Admin</span>
              </li>
              </>
            )
          }
          <p className="title">USEFUL</p>
          <li onClick={() => navigate("/stats")}>
            <InsertChartIcon className="icon" />
            <span>Analytics </span>
          </li>
          <p className="title">LIGHT / DARK</p>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
