import Home from "./pages/home/Home";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import NewAdmin from "./pages/newAdmin/NewAdmin";
import UpdateUser from "./pages/updateUser/UpdateUser";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { userInputs, adminInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import SingleUser from "./components/singleUser/SingleUser";
import ExchangeContact from "./components/exchangeContact/ExchangeContact";
import ExchangeContactConfrimation from "./components/exchangeContactConfrimation/exchangeContactConfrimation";
import UserAnalytic from "./components/userAnalytic/UserAnalytic";
import UserProfileShare from "./components/userProfileShare/UserProfileShare";
import Login from "./pages/login/Login";
import AdminLogin from "./components/adminLogin/AdminLogin.js";

import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import baseUrl from "./utils/config";
import Cookies from "js-cookie"


function App() {
  const { darkMode } = useContext(DarkModeContext);

  const accessToken = Cookies.get("adminToken");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const admin = await axios.get(`${baseUrl}/check-auth`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (admin.data.data.role == 'superadmin') {
        setIsSuperAdmin(true);
      }
    };
    checkAuth();
  }, []);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            {
              isSuperAdmin && (<Route path="admins">
                <Route index element={<List userType="admins" />} />
                <Route path="new" element={<NewAdmin inputs={adminInputs} title="Add New Admin"/>} />
              </Route>
            )}
            <Route path="users">
              <Route index element={<List />} />
              {isSuperAdmin && (<Route path=":userId" element={<Single />} />)}
              {
                isSuperAdmin && (
                  <Route path="new" element={<New inputs={userInputs} title="Add New User" />} />
                )
              }
            </Route>
            <Route path="stats">
              <Route index element={<Home page="stats" />} />
            </Route>
            <Route path="user">
              <Route index element={<List />} />
              <Route path=":userId" element={<SingleUser />} />
              {
                isSuperAdmin && (
                  <Route
                    path="new"
                    element={<New inputs={userInputs} title="Add New User" />}
                  />
                )
              }
              <Route
                path="update/:userId"
                element={<UpdateUser inputs={userInputs} title="Update User Data" permission='update' />}
              />
            </Route>
            <Route path="/view/:id" element={<SingleUser />} />
            <Route path="/exchange-contact" element={<ExchangeContact />} />
            <Route path="/exchange-contact-confirmation" element={<ExchangeContactConfrimation />} />
            <Route path="/user-analytic/:id" element={<UserAnalytic />} />
            <Route path="/share/:id" element={<UserProfileShare />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin-login" element={<AdminLogin />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
