import React, { useEffect, useState } from 'react'
import './userProfileShare.scss'
import ShareIcon from '@mui/icons-material/Share';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Loading from '../loading/loading';
import CloseIcon from '@mui/icons-material/Close';
import * as QRCode from 'easyqrcodejs';
import logo from "../../images/qrLogo.png"
import {ShareSocial} from 'react-share-social' 
import Modal from "react-modal";

Modal.setAppElement('#root');


const UserProfileShare = () => {
  const url = window.location.href;
  const urlId = url.substring(url.lastIndexOf("/") + 1, url.length);
  const [digiURl, setDigiURl] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const qrRef = React.createRef();


  const style = {
    root: {
      borderRadius: 3,
      border: 0,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      width: '70%'
    },
    copyContainer: {
      border: '1px solid blue',
      background: 'rgb(0,0,0,0.7)'
    },
    title: {
      color: 'aquamarine',
      fontStyle: 'italic'
    }
  };

  const genQR = (text) => {
    qrRef.current.innerHTML = "";
    new QRCode(qrRef.current, {
      text: text,
      width: 300,
      height: 300,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H,
      logo: logo,
      logoWidth: 60,
      logoHeight: 60,
      logoBackgroundTransparent: false,
      quietZone: 20,
      quietZoneColor: "#ffffff",
    });
    
  }

  const handleCloseModal = () => {
    setShowModal(false)
  };

  

  useEffect(() => {

    try {
      const url = "http://digitalmu-nfc.com/user/" + urlId;
      setDigiURl(url);
      genQR(url);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

  }, [qrRef])


  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Helmet>
      {isLoading ? <Loading></Loading> :
        (
          <>
            <div className="UserAnalyticNavbar">
              <div style={{ margin: "0px 20px" }} > </div>
              <p> Share </p>
              <div className="close-btn">
                <div className="exchange-contact-top-div-close-btn" onClick={() => { navigate(-1) }} >
                  <CloseIcon />
                </div>
              </div>
            </div>
            <div className='divider'></div>
            <div className="user-analytic-container">
              <div className='qr-container'>
                <h1> Sharing Personal Profile </h1>
                <div ref={qrRef}>  </div>
                <p> Tap to save QR code in your phone </p>
              </div>

              <div className='share-container'>
                <p> {digiURl.substring(0, 30)}... </p>
                <div style={{cursor: 'pointer'}} onClick={() => { setShowModal(true) }}>
                  <ShareIcon />
                </div>
              </div>
            </div>
          </>
        )}
        <Modal isOpen={showModal} onRequestClose={handleCloseModal} className="leadModalShareProfile">
          <button onClick={() => handleCloseModal()} > X </button>
          <ShareSocial url ={digiURl}  socialTypes={['facebook','twitter','telegram', 'email', 'whatsapp']} style={style} />
        </Modal>
    </>
  )
}

export default UserProfileShare