import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Datatable from "../../components/datatable/Datatable"
import DatatableAdmin from "../../components/datatable/DatatableAdmin"

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import baseUrl from "../../utils/config";
import Cookies from "js-cookie"


const List = ({ userType = "users" }) => {
  const navigate = useNavigate();

  const accessToken = Cookies.get("adminToken");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await axios.get(`${baseUrl}/check-auth`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setIsAuthenticated(true);
        setIsAuthenticating(false);
      } catch (error) {
        setIsAuthenticated(false);
        setIsAuthenticating(false);
        navigate("/admin-login");
        console.log("not authenticated");
      }
    };
    checkAuth();
  }, []);


  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        {userType == "users"  ? <Datatable/> : <DatatableAdmin/>}
      </div>
    </div>
  )
}

export default List