import "./newAdmin.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from 'react-spinners';
import { useEffect } from "react";
import baseUrl from '../../utils/config.js';
import Cookies from "js-cookie";


const NewAdmin = ({ inputs, title }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const accessToken = Cookies.get("adminToken");

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await axios.get(`${baseUrl}/check-auth`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setIsLoading(false)
      } catch (error) {
        console.log(error);
        navigate("/admin-login");
        console.log("not authenticated");
      }
    };
    checkAuth();
  }, []);

  const handleSubmit = async event => {
    setIsLoading(true);
    try { 

      if(event.target.password.value !== event.target.confrimpassword.value){
        throw 'Password not matching';
      }

      if(event.target.password.value.length < 8){
        throw 'Password not has to be more than 8 characters';
      }

      event.preventDefault();
      const form = new FormData();
      form.append('username', event.target.name.value);
      form.append('email', event.target.email.value);
      form.append('company', event.target.company.value);
      form.append('role', event.target.role.value);
      form.append('password', event.target.password.value);

      await axios({
        method: 'post',
        url: `${baseUrl}/signup`,
        data: form,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => {
        navigate("/admins")
      })
      .catch(error => {
        window.alert("Something went wrong");
      });

    }
    catch (error) {
      window.alert('something went wrong: ' + error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <span>
      {isLoading &&
        <div className='loading-spinner-background'>
          <BeatLoader
            size={15}
            color={"#6439ff"}
            loading={isLoading}
          />
        </div>
      }
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar />
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="right">
              <form onSubmit={handleSubmit} >
                {inputs.map((input) => (
                  input.name === "role" ?
                    <div className="formInput">
                      <label htmlFor="role">Role</label>
                      <select name="role" id="role">
                        <option selected value="admin">Admin</option>
                        <option value="superadmin">Super Admin</option>
                      </select>
                    </div>
                    :
                    <div className="formInput" key={input.id}>
                      <label htmlFor={input.name}>{input.label}</label>
                      <input type={input.type} placeholder={input.placeholder} name={input.name} id={input.name} />
                    </div>
                ))}
                <button>Add</button>
              </form>
            </div>
          </div>
        </div>
      </div >
    </span >
  );
};

export default NewAdmin;
