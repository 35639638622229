export const userInputs = [
  {
    id: 1,
    label: "Name*",
    name: "name",
    type: "text",
    placeholder: "Full Name Here",
  },
  {
    id: 2,
    name: "email",
    label: "Email*",
    type: "email",
    placeholder: "Email Here",
  },
  {
    id: 3,
    label: "Phone Number*",
    name: "phone",
    type: "number",
    placeholder: "e.g 08012345678",
  },
  {
    id: 4,
    name: "company",
    label: "Company*",
    type: "text",
    placeholder: "e.g Apple",
  },

  {
    id: 6,
    name: "address",
    label: "Address*",
    type: "text",
    placeholder: "e.g 123, Lagos, Nigeria",
  },
  {
    id: 7,
    name: "digitalmuUrl",
    label: "Digitalmu Url",
    type: "text",
    placeholder: "e.g https://digitalmu.com",
  },
  {
    id: 8,
    name: "designation",
    label: "Designation*",
    type: "text",
    placeholder: "e.g CEO",
  },
  {
    id: 9,
    name: "selectedColor",
    label: "Selected Theme",
    type: "color",
    placeholder: "e.g #000000",
  },
  {
    id: 14,
    name: "status",
    label: "Status",
    type: "text",
    placeholder: "e.g Active or Inactive",
  },
  {
    id: 10,
    name: "facebookUrl",
    label: "Facebook Url",
    type: "text",
    placeholder: "e.g https://facebook.com",
  },
  {
    id: 11,
    name: "twitterUrl",
    label: "Instagram Url",
    type: "text",
    placeholder: "e.g https://instagram.com",
  },
  {
    id: 12,
    name: "tiktokUrl",
    label: "Tiktok Url",
    type: "text",
    placeholder: "e.g https://tiktok.com",
  },
  {
    id: 13,
    name: "youtubeUrl",
    label: "Youtube Url",
    type: "text",
    placeholder: "e.g https://youtube.com",
  },
  {
    id: 14,
    name: "bio",
    label: "Bio",
    type: "text",
    placeholder: "e.g Work Hard, Play Hard",
  },
  {
    id: 15,
    name: "addContactReroute",
    label: "Redirect User",
    type: "url",
    placeholder: "e.g https://google.com",
  },
  {
    id: 16,
    name: "subscriptionType",
    label: "Subscription Type",
    type: "select",
    options: [
      "normal",
      "premium"
    ],
  },
];


export const adminInputs = [
  {
    id: 1,
    label: "Name*",
    name: "name",
    type: "text",
    placeholder: "Fullname",
  },
  {
    id: 2,
    name: "email",
    label: "Email*",
    type: "email",
    placeholder: "Email",
  },
  {
    id: 3,
    name: "company",
    label: "Company*",
    type: "text",
    placeholder: "e.g Apple",
  },
  {
    id: 4,
    name: "password",
    label: "Password*",
    type: "password",
    placeholder: "e.g Abc@123",
  },
  {
    id: 5,
    name: "confrimpassword",
    label: "Confrim Password*",
    type: "Password",
    placeholder: "e.g Abc@123",
  },
  {
    id: 6,
    name: "role",
    label: "Role*",
    type: "text",
    placeholder: "e.g Admin",
  },
];
