import React from 'react'
import './singleUser.scss'
import Cookies from "js-cookie";
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import { saveContact2 } from '../../utils/saveContact';
import baseUrl from '../../utils/config.js';
import Loading from '../loading/loading';
import PersonIcon from '@mui/icons-material/Person2Outlined';
import QRIcon from '@mui/icons-material/QrCode2Sharp';

import Lottie from 'react-lottie';
import animationDataPin from "../../images/8845-location-pin.json";

const themeType = {
    "blue": {
        class: 'blueTheme',
        backgroundImage: ``
    },
    "orange": {
        class: 'orangeTheme',
        backgroundImage: ``
    },
    "green": {
        class: 'greenTheme',
        backgroundImage: ``
    },
    "transparent": {
        class: 'transTheme',
        backgroundImage: ``
    },
    "grey": {
        class: 'greyTheme',
        backgroundImage: ``
    },
    "red": {
        class: 'redTheme',
        backgroundImage: ``
    },
    "lightgery": {
        class: 'lightGreyTheme',
        backgroundImage: ``
    },
    "yellow": {
        class: 'yellowTheme',
        backgroundImage: ``
    },
}


const SingleUser = () => {
    const navigate = useNavigate();
    const [img1, setImg1] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState({});
    const [theme, setTheme] = useState("blue");
    const [validYTLink, setValidYTLink] = useState(false);
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
    const url = window.location.href;

    const digiMuClick = async (digitalmuUrl) => {
        const accessToken = Cookies.get("adminToken");
        try {
            await axios.post(`${baseUrl}/digimuclick/${urlId}`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
        } catch (error) {
            console.log('Could not Record');
        }

        if (!window.open(digitalmuUrl)) {
            window.location.href = digitalmuUrl
        }
    }

    const convertYouTubeURL = (url) => {

        const getVideoIdFromURL = (url) => {
            var regex = /[?&]v=([^&#]*)/;
            var match = regex.exec(url);
            return match && match[1] ? match[1] : null;
        }

        var videoId = getVideoIdFromURL(url);
        if (videoId) {
            var embedUrl = "https://www.youtube.com/embed/" + videoId;
            return embedUrl;
        }

        return url;
    }

    const urlId = url.substring(url.lastIndexOf("/") + 1, url.length);
    useEffect(() => {
        const first = async () => {
            await axios.get(`${baseUrl}/get-user?userId=${urlId}`)
                .then(response => {
                    response.data.date = response.data.date.substring(0, 10);
                    setUser(response.data);
                    setImg1(response.data.img1);
                    setIsLoading(false);

                    if (response?.data?.selectedColor in themeType) {
                        setTheme(response?.data?.selectedColor);
                    } else {
                        setTheme("grey");
                    }

                    const regex = /^https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]{11}$/;
                    if (regex.test(response.data.youtubeUrl)) {
                        setValidYTLink(true);
                    }

                }).catch(error => console.log(error));
        }
        first();
    }, [urlId])

    const handleSaveContact = async () => {
        saveContact2(user?.name, user?.email, user?.phone, user?.company, user?.digitalmuUrl, urlId)
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationDataPin,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const formatAddress = (address) => {
        return encodeURIComponent(address);
    }

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, user-scalable=no" />
            </Helmet>

            {isLoading ? (<Loading></Loading>) : (
                <div className={'single-user-container ' + themeType[theme].class} style={{ background: "#f5f5f5" }} >
                    <div className='user-top-container'>
                        <div className='user-top-left'>
                            <img src={`${window.location.origin}/images/muLogo.png`} style={{background: theme === "transparent" ? "#fefefe" : ""}} className='next-icon' alt="icon" />
                        </div>
                        <div className='user-top-right' onClick={() => navigate("/login?userId=" + urlId)} >
                            <PersonIcon></PersonIcon>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div style={{ height: user?.status == "active" ? "auto" : "500px" }} className="single-user-data-container">
                        {user?.status == "inactive" ? (<div></div>) : (
                            <div className='user-core-data' >
                                <div className='user-core-data-card'>
                                    <div className="QRbuttonContainer" onClick={() => { navigate('/share/' + urlId) }} >
                                        <QRIcon style={{color: themeType[theme].class == "transTheme" ? "white" : 'black' }} />
                                    </div>
                                    <div className={"user-info-container " + themeType[theme].class}>
                                        <img onClick={() => setModalIsOpen(true)} src={img1 ? img1 : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"} className="ppic" alt="image" />
                                        <div className="user-info">
                                            <img src={user.img2} alt="logo" />
                                            <p className={'username'}> {user?.name}</p>
                                            <p className='position'> {user?.designation} </p>
                                            <p className='position'> {user?.bio} </p>
                                            {
                                                user?.company && user?.address && (
                                                    <a href={'https://www.google.com/maps?q=' + formatAddress(user?.address)} target='_blank' className='compcontainer'>
                                                        <Lottie options={defaultOptions}  height={40} width={20} />
                                                        <p className='others'> {user?.company} </p>
                                                    </a>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={'buttons-container ' + themeType[theme].class}>
                                    <button className={"btn"} style={{ cursor: "pointer", width: user?.digitalmuUrl == "" ? '70%' : '50%' }} onClick={handleSaveContact} >
                                        Save Contact
                                    </button>

                                    {user?.digitalmuUrl != "" && (
                                        <button onClick={() => digiMuClick(user.digitalmuUrl)} disabled={user?.digitalmuUrl ? false : true} style={{ cursor: user?.digitalmuUrl ? "pointer" : "not-allowed" }} className={"btn"}>
                                            About
                                        </button>
                                    )}
                                </div>

                                <div className="media-container">
                                    {
                                        (user?.facebookUrl || user?.youtubeUrl || user?.twitterUrl || user?.tiktokUrl) && 
                                        (<p className={themeType[theme].class}> Social Network </p>)
                                    }
                                    <div className="media-icons-container">
                                        {
                                            user?.facebookUrl &&
                                            (
                                                <div className='media-icon-container'>
                                                    <img src={`${window.location.origin}/images/facebook.svg`} alt="fb" onClick={() => window.open(user.facebookUrl, "_blank")} />
                                                </div>
                                            )
                                        }
                                        {
                                            user?.twitterUrl &&
                                            (
                                                <div className='media-icon-container'>
                                                    <img src={`${window.location.origin}/images/instagram.svg`} alt="twitter"
                                                        onClick={() => window.open(user.twitterUrl, "_blank")}
                                                    />
                                                </div>
                                            )
                                        }
                                        {
                                            user?.tiktokUrl &&
                                            (
                                                <div className='media-icon-container'>
                                                    <img src={`${window.location.origin}/images/tiktok.svg`} alt="tiktok"
                                                        onClick={() => window.open(user.tiktokUrl, "_blank")}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                    {user?.youtubeUrl && validYTLink && (<iframe height="400" src={convertYouTubeURL(user.youtubeUrl)}> </iframe>)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="Modal">
                <span className="img-span">
                    <img src={img1 ? img1 : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"} alt="image" className="modal-image" />
                    <span className="close-btn" onClick={closeModal}>
                        &#10005;
                    </span>
                </span>
            </Modal>
        </>
    )
}

export default SingleUser