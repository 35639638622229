import React, { useEffect, useState } from 'react'
import './userAnalytic.scss'
import AddIcon from '@mui/icons-material/Add';
import PhoneIcon from '@mui/icons-material/Phone';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import NoteIcon from '@mui/icons-material/Note';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import baseUrl from '../../utils/config.js';
import { useNavigate } from 'react-router-dom';
import { saveContact } from '../../utils/saveContact';
import Loading from '../loading/loading';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';
import csvInit from "../../utils/csvManager";

const UserAnalytic = () => {
  const url = window.location.href;
  // take user id from url general work for any url
  const urlId = url.substring(url.lastIndexOf("/") + 1, url.length);
  const [user, setUser] = useState({});
  const [contacts, setContacts] = useState([]);
  const [searchBy, setsearchBy] = useState("name");
  const [contactsViewed, setContactsViewed] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getHeadings = (data) => {
    return Object.keys(data[0] ?? []);
  }

  const downloadCSV = () => {
    const data = contacts.map((m) => {
      return {
        'Name': m.name?.replace(/,/g, '-') ?? '-',
        'Email': m.email?.replace(/,/g, '-') ?? '-',
        'Phone': m.phone?.replace(/,/g, '-') ?? '-',
        'Note': m.note?.replace(/,/g, '-') ?? '-',
      }
    });

    csvInit(getHeadings(data), data, user.name)
  };

  useEffect(() => {
    axios.get(`${baseUrl}/get-user?userId=${urlId}`).then(response => {
      setUser(response?.data)

      let contacts = response?.data?.contacts.reverse();
      setContacts(contacts);
      setContactsViewed(contacts);
      setIsLoading(false);
    }).catch(error => {
      if (error.response.status === 404) {
        console.log("user not found")
      }
      console.log(error)
    })
  }, [])
  
  const navigate = useNavigate()
  const handleClick = (contact) => {
    saveContact(contact?.name, contact?.email, contact?.phone, contact?.company, contact?.digitalmuUrl)
  }

  const search = (searchKey) => {
    if(searchKey === "" || searchKey === null){
      setContactsViewed(contacts);
    }

    if(searchBy === 'name'){
      let matches = [];
      contacts.forEach(element => {
        if(element.name.toLowerCase().includes(searchKey.toLowerCase())){
          matches.push(element);
        }
      });
      setContactsViewed(matches);
      
    }else if (searchBy === 'note') {

      let matches = [];
      contacts.forEach(element => {
        if(element?.note?.toLowerCase().includes(searchKey.toLowerCase())){
          matches.push(element);
        }
      });
      setContactsViewed(matches);
    }
  }

  const formatDate = (timestamp) => {
    let date = new Date(timestamp);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    
    if (dt < 10) {
      dt = '0' + dt;
    }

    if (month < 10) {
      month = '0' + month;
    }
    
    return year + '-' + month + '-' + dt;
  }

  const formatTime = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString();;
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Helmet>
      {isLoading ? <Loading></Loading> :
        (
          <>
            <div className="UserAnalyticNavbar">
              <div style={{ margin: "0px 20px" }} >
                <div className="exchange-contact-top-div-close-btn close-btn" onClick={() => { downloadCSV() }} >
                  <DownloadIcon />
                </div>
              </div>
              <p> Leads Report </p>
              <div className="close-btn">
                <div className="exchange-contact-top-div-close-btn" onClick={() => {navigate(-1)}} >
                  <CloseIcon />
                </div>
              </div>
            </div>
            <div className='divider'></div>
            <div className="user-analytic-container">
              <div className="user-second-row-container">
                <div className="left">
                  <h1>{user.taps}</h1>
                  <p>No of Taps</p>
                </div>
                <div className="right">
                  <h1>{contacts.length}</h1>
                  <p>Leads Captured</p>
                </div>
              </div>

              <div className="input-wrapper">
                <SearchIcon style={{marginLeft: '10px'}} />
                <input onChange={(e) => search(e.target.value)} type="text" placeholder='Search'/>
                <select onChange={(e) => setsearchBy(e.target.value)}>
                  <option value="name"> name </option>
                  <option value="note"> note </option>
                </select>
              </div>

              {
                contactsViewed.map((contact) => {
                  return (
                    <div className="lead-container">
                      <div className="plus-icon-container"
                        onClick={() => handleClick(contact)}
                      >
                        <AddIcon className='addIcon' />
                      </div>
                      <div className="text-container">
                        <p style={{textAlign: "start"}} >{contact.name}</p>
                        <div className="text-container-row">
                          <span>
                            <PhoneIcon className='Icon' />
                          </span>
                          <span>{contact.phone}</span>
                        </div>
                        {contact.email && <div className="text-container-row">
                          <span>
                            <ForwardToInboxIcon className='Icon' />
                          </span>
                          <span>{contact.email}</span>
                        </div>}

                        <div style={{width: '100%'}}>
                          {
                            contact.note && <div className="text-container-row">
                              <span>
                                <NoteIcon className='Icon' />
                              </span>
                              <span>{contact.note}</span>
                            </div>
                          }
                          {
                            contact.createdAt && <div>
                              <div style={{width: '100%', textAlign: 'end',padding: '5px'}}>{formatDate(contact.createdAt)}</div>
                              <div style={{width: '100%', textAlign: 'end',padding: '5px'}}>{formatTime(contact.createdAt)}</div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
                }
            </div>
          </>
        )}
    </>
  )
}

export default UserAnalytic